import Main from 'components/Main';
import {React,useState,useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.css';



  
export default function App() {
   

      
    return (
        <>
         <Main/>
           
        </>
    )
}
